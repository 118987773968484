



























































































import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router";

@Component
export default class Purchases extends Vue {
  private mode = "create";
  private name = "Manufacturing Voucher";
  private modulename = "voucher";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { date: this.today, description: "", machineId: null, voucherNumber: "", reference: "", inventoryEntriesIn: [], inventoryEntriesOut: [], voucherType: "Manufacturing" }
  private inputTemp: any = { item: null, quantity: 0, waste: 0 };
  private selectAll = false;
  private selections: Array<number> = [];

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  @Watch("selectAll")
  selectAllChanged(value: boolean) {
    if (value) {
      const count = this.inventoriesIn.length;
      this.selections = [];
      for (let i = 0; i < count; i++) {
        this.selections.push(i);
      }
    } else {
      this.selections = []
    }
  }

  addEntry () {
    const sItem: any = { itemId: this.inputTemp.item, quantity: this.inputTemp.quantity, waste: this.inputTemp.waste }
    sItem.name = this.stockitems.filter((i: any) => i._id.toString() === sItem.itemId.toString())[0].name;
    this.data.inventoryEntriesIn.push(sItem);
    this.inputTemp = { item: null, quantity: 0, waste: 0 }
  }

  removeEntry () {
    const indices: Array<number> = this.selections.map(i => i);
    indices.sort((a: number, b: number) => (a > b ? -1 : 1))
    for (let i = 0; i < indices.length; i++) {
      this.data.inventoryEntriesIn.splice(indices[i], 1);
    }
    this.selections = [];
    this.selectAll = false;
  }

  save () {
    if (this.mode === 'create') {
      const data = {...this.data}
      data.voucherNumber = this.voucherNumber;
      this.$store.dispatch(`${this.modulename}/create`, { data, callback: this.onSaved });
    } else if (this.mode === 'alter') {
      const data = { ...this.data }
      const id = data._id;
      delete data._id;
      this.$store.dispatch(`${this.modulename}/patch`,{ id, data, callback: this.onSaved });
    }
  }

  remove () {
    if (this.selectedItem) {
      this.$store.dispatch(`${this.modulename}/remove`,{ id: this.selectedItem, callback: this.onRemoved });
    }
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = this.mode === "create" ? `${this.name} Successfully Created!` : `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
      if (this.mode === "alter") this.$router.replace("/dashboard/reports/daybook")
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
      this.$router.replace("/dashboard/reports/daybook")
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { date: this.today, description: "", machineId: null, voucherNumber: "", reference: "", inventoryEntriesIn: [], inventoryEntriesOut: [], voucherType: "Manufacturing" }
    this.selectedItem = null;
    this.tempItem = null;
    this.selectAll = false;
    this.selections = [];
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const items = this.$store.state[this.modulename].items;
    return items;
  }

  get stockitems () {
    const items = this.$store.state.stockitem.items;
    return items;
  }

  get machines () {
    const items = this.$store.state.machine.items;
    return items;
  }

  get units () {
    const items = this.$store.state.unit.items;
    const dict: any = {}
    for (let i = 0; i < items.length; i++) {
      dict[`${items[i]._id}`] = items[i].plural;
    }
    return dict;
  }

  get canSave () {
    return this.data.date && this.data.machineId && this.data.inventoryEntriesIn && this.data.inventoryEntriesIn.length > 0
  }

  get canRemove () {
    return this.selectedItem
  }

  get today() {
    const dt = new Date(Date.now());
    return this.formatDate(dt);
  }

  formatDate(dt: Date) {
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();
    const ms = m < 10 ? `0${m}` : m.toString();
    const ds = d < 10 ? `0${d}` : d.toString();
    return `${y}-${ms}-${ds}`;
  }

  get inventoriesIn() {
    const items: Array<any> = this.data.inventoryEntriesIn;
    const ids: Array<string> = items.map((i: any) => i.itemId.toString())
    const sitems = this.stockitems.filter((i: any) => ids.includes(i._id.toString()))
    const units = this.units;
    items.forEach((itm: any) => {
      const sitm = sitems.filter((i: any) => i._id.toString() === itm.itemId.toString())[0];
      if (sitm) {
        itm.name = sitm.name;
        itm.qty = `${itm.quantity} ${units[sitm.unit]}`
      } else {
        itm.name = "<NOT FOUND>";
      }
    })
    return items;
  }

  loadVoucher (voucherId: any) {
    this.$store.dispatch(`${this.modulename}/get`, { id: voucherId, callback: this.onLoaded });
  }

  onLoaded (e: any, d: any) {
    if (d) {
      this.data = d;
      this.data.date = this.formatDate(new Date(this.data.date));
      this.selectedItem = d._id;
    }
  }

  get voucherNumber () {
    const vn = this.$store.getters["setting/nextVoucherNumber"]
    return vn;
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', false);
    this.$store.dispatch(`${this.modulename}/load`)
    this.$store.dispatch(`stockitem/load`)
    this.$store.dispatch(`machine/load`)
    this.$store.dispatch(`unit/load`)
    this.$store.dispatch(`setting/load`)

    if (this.$route.params.mode) {
      this.mode = this.$route.params.mode
    }

    if (this.$route.params.voucherId) {
      this.loadVoucher(this.$route.params.voucherId);
    }
  }

}
